<template>
  <footer class="hero-footer footer">
    <div class="container">
      <div class="columns">


        <div class="column is-1"></div>
        <div class="column is-3">
          <span class="title">Solutions</span>
          <ul class="bulletless">
              <li><a href="#">Customer Success Teams</a></li>
              <li><a href="#">Development Teams</a></li>
              <li><a href="#">Sales Teams</a></li>
              <li><a href="#">Security Teams</a></li>
              <li><a href="#">DevOps Teams</a></li>
              <li><a href="#">Customer Solutions</a></li>
              <li><a href="#">Onboarding</a></li>
              <li><a href="/product/integrations">Integrations</a></li>
          </ul>
        </div>

        <div class="column is-3">
          <span class="title">Resources</span>
          <ul class="bulletless">
              <li><a href="/solutions/support">Help & Support</a></li>
              <li><a href="/resources/docs">Documentation</a></li>
              <li><a href="/resources/faq">FAQ</a></li>
              <li><a href="#">Terms of Use</a></li>
              <li><a href="#">Security</a></li>
              <li><a href="#">Status</a></li>
              <li><a href="#">Learning Center</a></li>
          </ul>
        </div>

        <div class="column is-3">
          <span class="title">Company</span>
          <ul class="bulletless">
              <li><a href="/company/about">About Us</a></li>
              <li><a href="/company/careers">Careers</a></li>
              <li><a href="/company/press">Press Releases</a></li>
              <li><a href="/resources/blog">Blog</a></li>
              <li><a href="">Customer Solutions</a></li>
              <li><a href="#">Onboarding</a></li>
              <li><a href="#">Testimonials</a></li>
          </ul>
        </div>

        <div class="column is-2">
          <div class="container mb-3">
          <span class="title">Contacts - SF</span>
          <ul class="bulletless">
              <li><a href="#">Street, Block</a></li>
              <li><a href="#">Room</a></li>
              <li><a href="#">San Francisco,  CA 10016</a></li>
              <li><a href="#">(715) 212-2131</a></li>
          </ul>
          </div>
          <div class="container mb-3">
          <span class="title">Contacts - NYC</span>
          <ul class="bulletless">
              <li><a href="#">Street, Block</a></li>
              <li><a href="#">Room</a></li>
              <li><a href="#">New York,  NY 10016</a></li>
              <li><a href="#">(212) 212-2131</a></li>
          </ul>
          </div>
        </div>
  
        <div class="column is-2"></div>


      </div>
      <div class="columns">
        <div class="column is-4"></div>
        <div class="container column s-4 has-text-centered">         
            <ul class="bulletless">
                <li>Copyright © 2020 3D Act, Inc. All rights reserved.</li>
                <li>
                   <span class="disclaimer">
                   <a href="#">Privacy Policy</a> &nbsp; 
                   <a href="#">Disclaimers</a> &nbsp;
                   <a href="#">Security</a> &nbsp;
                   <a href="#">Terms of Use</a>
                   </span>
                </li>
            </ul>
        </div>

        <div class="column is-4"></div>
      </div>  
  </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
